import Swiper from 'swiper'
import { Autoplay, Controller, Navigation, Pagination } from 'swiper/modules'
import { register } from 'swiper/element/bundle'

function init() {
  register()


}

export default { init }
